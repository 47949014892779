import IPbiDashboard from "../models/IPbiDashboard";
import { fetchSecureData } from "../utils";

export const getUserDashboards = async (
  accessToken: string,
    userId: number,
    userGuid: string
) => {
  try {
    const data = await fetchSecureData(
      "GET",
        `/api/PbiDashboard/GetPbiDashboardsForUserId?userGuid=${userGuid}`,
      accessToken
    );
    const userDashboards = ((await data.json()) as any)
      .value as IPbiDashboard[];
    if (userDashboards !== undefined) {
      return userDashboards;
    } else {
      return [] as IPbiDashboard[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/PbiDashboard/GetPbiDashboardsForUserId?userGuid=${userGuid}`,
      err
    );
  }
  return [] as IPbiDashboard[];
};

export default getUserDashboards;
