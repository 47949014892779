import * as Redux from "redux";
import getUserFavorites from "../api/getUserFavorites";
import saveUserFavorite from "../api/saveUserFavorite";
import { removeUserFavorite as deleteUserfavorite } from "../api/removeUserFavorite";


import IFavorite from "../models/IUserFavorite";
import { setUserFavoritesLoaded } from "./loadingUserFavorites";


export const ADD_USER_FAVORITE = "ADD_USER_FAVORITE";
export const REMOVE_USER_FAVORITE = "REMOVE_USER_FAVORITE";
export const LOAD_USER_FAVORITES = "LOAD_USER_FAVORITES";



export const updateUserFavorite = (userFavorite: IFavorite) => 
{
    return {
        userFavorite,   
        type: ADD_USER_FAVORITE
    };
};



export const removeUserFavorite = (id: number) => {
    return {
        id,
        type: REMOVE_USER_FAVORITE
    };
};




export const handleAddFavorite = (
    accessToken: string,
    userFavorite: IFavorite,
    userGuid:string
) => {
    return async (dispatch: Redux.Dispatch) => {
        const userFav = await saveUserFavorite(accessToken, userFavorite, userGuid);        
        dispatch(updateUserFavorite(userFav));
        
    };
};


export const handleDeleteFavorite = (
    accessToken: string,
    id: number
) => {
    return async (dispatch: Redux.Dispatch) => {
        const result = await deleteUserfavorite(accessToken, id);
        if (result === 200) {
            dispatch(removeUserFavorite(id));
        }
    };
};




export const loadUserFavorites = (userFavorites: IFavorite[]) => {
    return {
        userFavorites,         
        type: LOAD_USER_FAVORITES
    };
};
 

export const handleLoadUserFavorites = (
    accessToken: string,
    userId: number,
    userGuid: string
) => {
    return async (dispatch: Redux.Dispatch<any>) => {
        const favorites = await getUserFavorites(accessToken, userId, userGuid);
        if (favorites !== undefined) {
            if (favorites.length > 0) {
                dispatch(loadUserFavorites(favorites));
            }
            dispatch(setUserFavoritesLoaded(true));
        }
    };

};
