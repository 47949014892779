import * as React from "react";
import { connect } from "react-redux";
import {
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row
} from "reactstrap";
import {
    clearEditScheduleUserIds,
    handleDeleteEditScheduleUserId,
    handleLoadEditScheduleUserIds,
    handleSaveEditScheduleUserId
} from "../../actions/editScheduleUserIds";
import { handleLoadUserProfiles } from "../../actions/profiles";
import ScheduleUsersBadge from "../../components/molecules/ScheduleUsersBadge";
import DataLoading from "../../components/organisms/DataLoading";
import { IStore } from "../../models/IStore";
import IUserProfile from "../../models/IUserProfile";
import IScheduleRoleUser from "../../models/IScheduleRoleUser";
import { handleLoadUserRolesWithUserProfiles } from "../../actions/userRolesWithUserProfiles";
import IScheduleRoleUserIds from "../../models/IScheduleRoleUserIds";
 

export interface IAssignScheduleUsersState {
    editScheduleUserIds: IScheduleRoleUserIds[];
    filteredUsers: IScheduleRoleUser[];
    userRolesWithUserProfiles: IScheduleRoleUser[];
}

export interface IAssignScheduleUsersProps {
    accessToken: string;
    activeUser: IUserProfile;
    users: IUserProfile[];
    usersLoaded: boolean;
    editScheduleUserIds: IScheduleRoleUserIds[];
    editScheduleUserIdsLoaded: boolean;
    scheduleId: number;
    userRolesWithUserProfiles: IScheduleRoleUser[];
    userRolesWithUserProfilesLoaded: boolean;
    onHandleLoadUserRolesWithUserProfiles: (accessToken: string, userId: number, userGuid:string) => void;

    onHandleLoadUsers: (accessToken: string) => void;
    onHandleLoadEditScheduleUserIds: (accessToken: string, scheduleId: number) => void;
    onClearEditScheduleUserIds: () => void;
    onHandleSaveEditScheduleUserId: (
        accessToken: string,
        scheduleId: number,
        roleIs: number,
        userId: number
    ) => void;
    onHandleDeleteEditScheduleUserId: (
        accessToken: string,
        scheduleId: number,
        roleIs:number,
        userId: number
    ) => void;
}

class AssignScheduleUsers extends React.Component<
    IAssignScheduleUsersProps,
    IAssignScheduleUsersState
> {
    public state = {
        editScheduleUserIds: [] as IScheduleRoleUserIds[],
        filteredUsers: [] as IScheduleRoleUser[],
        checked: [],
        expanded: [],
        userRolesWithUserProfiles: [] as IScheduleRoleUser[]

    };
    constructor(props: IAssignScheduleUsersProps) {
        super(props);
        this.userCheckboxChange = this.userCheckboxChange.bind(this);
        this.txtFilterChange = this.txtFilterChange.bind(this);

    }
    public txtFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { userRolesWithUserProfiles } = this.props;
        const filteredUsers = userRolesWithUserProfiles.filter(user =>
            user.emailAddress.toLowerCase().includes(e.target.value.toLowerCase())
        );
        await this.setState(() => ({ filteredUsers }));
    };
    public userCheckboxChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        roleId: number
    ) => {
        const {
            accessToken,
            scheduleId,
            onHandleSaveEditScheduleUserId,
            onHandleDeleteEditScheduleUserId
        } = this.props;
        e.preventDefault();
        if (e.target.checked) {
            await onHandleSaveEditScheduleUserId(
                accessToken,
                scheduleId,
                roleId,
                Number(e.target.value)
            );
        } else {
            await onHandleDeleteEditScheduleUserId(
                accessToken,
                scheduleId,
                roleId,
                Number(e.target.value)
            );
        }
    };

    public async componentWillMount() {
        const {
            onClearEditScheduleUserIds
        } = this.props;

        await onClearEditScheduleUserIds();

    }

    //public async componentWillUnmount() {
    //    const {
    //        onClearEditScheduleUserIds
    //    } = this.props;

    //    await onClearEditScheduleUserIds();

    //}




    public async componentDidMount() {
        const {
            accessToken,           
            usersLoaded,
            editScheduleUserIds,
            onHandleLoadUsers,
            onHandleLoadEditScheduleUserIds,
            scheduleId,
            activeUser,
            userRolesWithUserProfilesLoaded,
            onHandleLoadUserRolesWithUserProfiles,
            userRolesWithUserProfiles
        } = this.props;



        if (!userRolesWithUserProfilesLoaded) {
            await onHandleLoadUserRolesWithUserProfiles(accessToken, activeUser.id, activeUser.guid);
        }
        this.setState({ userRolesWithUserProfiles });

        if (!usersLoaded) {
            await onHandleLoadUsers(accessToken);
        }
        await onHandleLoadEditScheduleUserIds(accessToken, scheduleId);
        this.setState({ editScheduleUserIds });
         
        this.setState({ filteredUsers: userRolesWithUserProfiles });
    }




    public async componentDidUpdate(prevProps: IAssignScheduleUsersProps) {
        const { editScheduleUserIds, userRolesWithUserProfiles } = this.props;
        if (prevProps.userRolesWithUserProfiles !== userRolesWithUserProfiles) {
            await this.setState(() => ({ filteredUsers: userRolesWithUserProfiles }));
        }
        if (prevProps.editScheduleUserIds !== editScheduleUserIds) {
            await this.setState(() => ({ editScheduleUserIds }));
        }
         
    }

    // List of roles can be wider then  list of current user list
    public getRoleName = (roleId: number) => {
        const { userRolesWithUserProfiles } = this.state;

        return (userRolesWithUserProfiles.find(element => element.roleId === roleId) as IScheduleRoleUser).roleDisplayName;

    }


    public getRoleUsers = (roleId: number) => {
        const { filteredUsers} = this.state;

        let roleUsers = filteredUsers.filter(param => param.roleId === roleId);

        return roleUsers;
    }




    public render() {
        const { editScheduleUserIds, userRolesWithUserProfiles } = this.state;
        const { users, usersLoaded, editScheduleUserIdsLoaded } = this.props;


        const rolesIdsList = Array.from(new Set(userRolesWithUserProfiles.map((item: IScheduleRoleUser) => item.roleId)));
         
        return (

            <React.Fragment>
                {!usersLoaded && <DataLoading dataTitle="Users " />}
                {!editScheduleUserIdsLoaded && <DataLoading dataTitle="Schedule Users " />}
                {usersLoaded && editScheduleUserIdsLoaded && (
                    <Container >                       
                        <Row>
                            <Col style={{ width: "400px" }}>
                                {usersLoaded && (
                                    <ScheduleUsersBadge users={userRolesWithUserProfiles} userIds={editScheduleUserIds } />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>                             
                                <Input
                                    type="text"
                                    id="txtFilter"
                                    onChange={this.txtFilterChange}
                                    placeholder="Search for..."
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {

                                    rolesIdsList.map((roleId: number) => (
                                        <div key={roleId}>
                                            <h2 key={roleId} >{this.getRoleName(roleId)}</h2>

                                    <ListGroup >
                                                {(this.getRoleUsers(roleId)).map(user => (
                                                    <ListGroupItem key={user.roleId + "/"+ user.userProfileId}>
                                                <FormGroup check={true}>
                                                    <Label check={true}>
                                                        <Input
                                                            type="checkbox"
                                                                    value={user.userProfileId}
                                                                    onChange={e => this.userCheckboxChange(e, user.roleId)}                                                                 
                                                            checked={
                                                                this.state.editScheduleUserIds.find(
                                                                    el => el.userId === user.userProfileId &&  el.roleId===user.roleId
                                                                ) !== undefined
                                                            }
                                                        />
                                                        {user.emailAddress}/
                                                        {user.roleDisplayName}
                                                    </Label>
                                                </FormGroup>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
       
                                        </div>
                                          
                                    ))} 


                            </Col>
                        </Row>
                    </Container>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: any) => {
    const { onSave } = ownProps;
    return {
        accessToken: state.auth0.accessToken,
        users: state.profiles,
        usersLoaded: state.loadingUsers,
        activeUser: state.userProfile,

        userRolesWithUserProfiles: state.userRolesWithUserProfiles,
        userRolesWithUserProfilesLoaded: state.loadingUserRolesWithUserProfiles, 
        // tslint:disable-next-line:object-literal-sort-keys
         
        scheduleId: state.editScheduleDetails.id,
        editScheduleUserIds: state.editScheduleUserIds,
        editScheduleUserIdsLoaded: state.loadingEditScheduleUsers,
        onSave
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadUsers: (accessToken: string) => {
        dispatch(handleLoadUserProfiles(accessToken));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleLoadEditScheduleUserIds: (accessToken: string, scheduleId: number) => {
        dispatch(handleLoadEditScheduleUserIds(accessToken, scheduleId));
    },
    onClearEditScheduleUserIds: () => {
        dispatch(clearEditScheduleUserIds());
    },

    onHandleLoadUserRolesWithUserProfiles: (accessToken: string, userId: number, userGuid:string) => {
        dispatch(handleLoadUserRolesWithUserProfiles(accessToken, userId, userGuid));
    },

     

    onHandleSaveEditScheduleUserId: (
        accessToken: string,
        scheduleId: number,
        roleId: number,
        userId: number
    ) => {
        dispatch(handleSaveEditScheduleUserId(accessToken, scheduleId, roleId, userId));
    },

    

    onHandleDeleteEditScheduleUserId: (
        accessToken: string,
        scheduleId: number,
        roleId: number,
        userId: number
    ) => {
        dispatch(handleDeleteEditScheduleUserId(accessToken, scheduleId, roleId, userId));
    }


});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignScheduleUsers);
