import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlProdS2TProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlProdS2T extends React.Component<
  ICallbackSamlProdS2TProps
> {
    public constructor(props: ICallbackSamlProdS2TProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

    auth.loginSAMLProdS2T().then(() => {
      console.log("Login via SAML Prod S2T");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlProdS2T);
