import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    Table,
    Button
} from "reactstrap";
import { IStore } from "../../models/IStore";
import Auth from "../../auth/Auth";
import IUserSchedule from "../../models/IUserSchedule";
import { handleLoadUserSchedules } from "../../actions/userSchedules";

import DownloadIcon from "../../components/molecules/DownloadIcon";


export interface IEmailReportsPageProps extends RouteComponentProps {
    accessToken: string;
    jobId: number;
    scheduleId: number;
    auth: Auth;
    isAuthenticated: boolean;
    onHandleLoadUserSchedules: (accessToken: string, userId: number, userGuid:string, jobId: number, scheduleId: number) => void;
    userSchedules: IUserSchedule[];
    userSchedulesLoaded: boolean;
    userId: number;
    userGuid: string;
}
export interface IEmailReportsPageState {

}

export class EmailReportsPage extends React.Component<
    IEmailReportsPageProps,
    IEmailReportsPageState
> {
    public isLoaded = false;

    constructor(props: IEmailReportsPageProps) {
        super(props);
        this.getScheduleName = this.getScheduleName.bind(this);
        this.getRoleName = this.getRoleName.bind(this);
        this.getReportName = this.getReportName.bind(this);
        this.getZipFileLink = this.getZipFileLink.bind(this);
        this.getSchedulePeriodDates = this.getSchedulePeriodDates.bind(this);
        this.convertUTCTimeToLocalTime = this.convertUTCTimeToLocalTime.bind(this);
        this.manualSave = this.manualSave.bind(this);
    }


    public getScheduleIdsList() {
        const { userSchedules
        } = this.props;
        return Array.from(new Set(userSchedules.map((item: IUserSchedule) => item.scheduleId)))
    }


    public getRoleIdsList(scheduleId: number) {
        const { userSchedules
        } = this.props;
        return Array.from(new Set(userSchedules.map((item: IUserSchedule) => item.scheduleId === scheduleId && item.roleId)))
    }


    public getReportList(scheduleId: number, roleId: number) {
        const { userSchedules
        } = this.props;
        //  return Array.from(new Set(userSchedules.map((item: IUserSchedule) => item.scheduleId === scheduleId && item.roleId === roleId && item.reportId)))
        var result = userSchedules.filter(element => element.scheduleId === scheduleId && element.roleId === roleId);

        return result;

    }


    public convertUTCTimeToLocalTime(UTCDateString: string) {
        var convertdLocalTime = new Date(UTCDateString);
        var hourOffset = convertdLocalTime.getTimezoneOffset() / 60 * -1;
        convertdLocalTime.setHours(convertdLocalTime.getHours() + hourOffset);
        return convertdLocalTime;
    }

    public getScheduleName() {
        const {
            userSchedules
        } = this.props;

        const schedule = userSchedules[0];


        return (<div>{schedule.scheduleName} </div>);
    }

    public getSchedulePeriodDates() {
        const {
            userSchedules
        } = this.props;

        const schedule = userSchedules[0];
        var lastUpdatedDateForJob = this.convertUTCTimeToLocalTime(schedule.runDate.toString())
        var period = "";

        if (schedule.periodDateFrom === schedule.periodDateTo) {
            period = (new Date(schedule.periodDateFrom)).toLocaleDateString();
        }
        else {
            period = (new Date(schedule.periodDateFrom)).toLocaleDateString() + ' - ' + (new Date(schedule.periodDateTo)).toLocaleDateString();
        }


        // return (<div>Report Period: {(new Date(schedule.periodDateFrom)).toLocaleDateString()} - {(new Date(schedule.periodDateTo)).toLocaleDateString()} (run at {(new Date(lastUpdatedDateForJob)).toLocaleString()})</div>);
        return (<div>Report Period: {period} (run at {(new Date(lastUpdatedDateForJob)).toLocaleString()})</div>);
    }




    public getRoleName(roleId: number) {
        const { userSchedules
        } = this.props;

        const role = userSchedules.find(element => element.roleId === roleId);
        return "Role: " + role?.roleName;

    }

    public getReportName(id: number) {
        const { userSchedules
        } = this.props;
        var fileFormat = "";
        const report = userSchedules.find(element => element.id === id);

        switch (report?.fileFormat) {
            case "PDF":
                fileFormat = "pdf";
                break;
            case "POWERPOINT":
                fileFormat = "pptx";
                break;
            case "EXCEL":
                fileFormat = "xlsx";
                break;
        }

        return (<a href={report?.exportedReportUrl} >{report?.reportName}.{fileFormat}</a>);
    }


    public getZipFileLink() {
        const { jobId, scheduleId, userId
        } = this.props;

        const res = `https://ctmsmartdatafilezipfunction.azurewebsites.net/api/Function1?scheduleId=${scheduleId}&jobId=${jobId}&userId=${userId}&code=8Q5fRuboO8xGsCeopss5v7lP2YkYMFq2qNuo_UxopFrtAzFu5lwPdQ==`;

       // const res = ` http://localhost:7279/api/Function1??scheduleId=${scheduleId}&jobId=${jobId}&userId=${userId}&code=8Q5fRuboO8xGsCeopss5v7lP2YkYMFq2qNuo_UxopFrtAzFu5lwPdQ==`;


        //const res = `https://ctmsmartdatafilezipfunctionprod.azurewebsites.net/api/Function1?scheduleId=${scheduleId}&jobId=${jobId}&userId=${userId}&code=N1aszITrsdxSDLaWFNhEM0p_kdK7oR0cDbl5HYU-lTUuAzFurnCMCg==`;
        //const res = `http://localhost:7071/api/Function1?scheduleId=41&jobId=5&userId=5071`
        //return (<a href={`https://ctmsmartdatafilezipfunction.azurewebsites.net/api/Function1?scheduleId=${scheduleId}&jobId=${jobId}&userId=${userId}&code=30/eeGiRg/OzCMyR7OvS1rmoSm4Y8Ta9jVUJH2Ko2ViMtyFdArcjpQ==`} >Download all reports as zip file</a>);
        return res;
    }


    public async componentDidUpdate(prevProps: IEmailReportsPageProps) {
        const { userId, accessToken, onHandleLoadUserSchedules, jobId, scheduleId, userGuid } = this.props;
        if (userId !== prevProps.userId) {
            onHandleLoadUserSchedules(accessToken, userId, userGuid, jobId, scheduleId);
        }
    }

    public async componentDidMount() {
        const { auth,
            isAuthenticated,
            jobId,
            scheduleId,
            onHandleLoadUserSchedules,
            userSchedulesLoaded,
            userId,
            userGuid,
            accessToken
        } = this.props;

        if (!isAuthenticated) {
            auth.loginEmail(jobId + "/" + scheduleId);
        }
        else {
            this.isLoaded = true;

            if (this.isLoaded && userId !== undefined) {
                if (!userSchedulesLoaded) {
                    onHandleLoadUserSchedules(accessToken, userId, userGuid, jobId, scheduleId);
                }
            }

        }
    }

    public manualSave() {

        const url = this.getZipFileLink();
        //window.open(window.location.href, '_blank');

        window.open(url, "_blank"); 

    }


    public render() {
        const { isAuthenticated, userSchedules } = this.props;
        return (
            <Form>
                <Card>
                    <CardHeader><h1>Email Reports </h1></CardHeader>
                    <CardBody>
                        <React.Fragment>
                            {!isAuthenticated && (
                                <h1>You are not Authenticated</h1>
                            )}

                            {isAuthenticated && (
                                userSchedules.length > 0 &&

                                this.getScheduleIdsList().map((id: any) => (
                                    <div>
                                        <h1>{this.getScheduleName()}</h1>
                                        {this.getSchedulePeriodDates()}
                                        {
                                            this.getRoleIdsList(id).map((roleId: any) => (
                                                <div>
                                                    <h2>{this.getRoleName(roleId)}</h2>

                                                    <Table striped={true} bordered>
                                                        <tbody>
                                                            {
                                                                this.getReportList(id, roleId).map((report: IUserSchedule) => ( 
                                                                    <tr>
                                                                        <td>{this.getReportName(report.id)}</td>                                                                      
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>

                                                </div>

                                            ))
                                        }
                                        <Table striped={true} bordered>
                                            <tbody>
                                                <tr>
                                                    <td><Button onClick={this.manualSave}> Download all reports as zip file</Button></td>
                                                    {/*<td><DownloadIcon /> {this.getZipFileLink()}</td>*/}
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                ))
                            )
                            }


                        </React.Fragment>
                    </CardBody>

                </Card>
            </Form>
        );
    }
}

const mapStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
    const { match } = ownProps;
    let jobId = 0;
    let scheduleId = 0;
    if (match !== null) {
        jobId = parseInt((match.params as any).jobId as string, 10);
        scheduleId = parseInt((match.params as any).scheduleId as string, 10);
    }
    return {
        accessToken: state.auth0.accessToken,
        jobId,
        scheduleId,
        auth: state.auth0,
        isAuthenticated: state.auth0.isAuthenticated(),
        userSchedules: state.userSchedules,
        userSchedulesLoaded: state.loadingUserSchedules,
        userId: state.userProfile.id,
        userGuid:state.userProfile.guid

    };
};
const mapDispatchToProps = (dispatch: any) => ({
    onHandleLoadUserSchedules: (accessToken: string, userId: number, userGuid:string, jobId: number, scheduleId: number) => {
        dispatch(handleLoadUserSchedules(accessToken, userId,userGuid, jobId, scheduleId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailReportsPage);
