import IUserFavorite from "../models/IUserFavorite";
import { fetchSecureData } from "../utils";

export const getUserFavorites = async (
    accessToken: string,
    userId: number,
    userGuid: string
) => {
    try {
        const data = await fetchSecureData(
            "GET",
            `/api/UserProfileFavorite/GetUserFavoritesForUserId?userGuid=${userGuid}`,
            accessToken
        );
        const userFavorites = ((await data.json()) as any)
            .value as IUserFavorite[];
        if (userFavorites !== undefined) {
            return userFavorites;
        } else {
            return [] as IUserFavorite[];
        }
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(
            `Fetch error for /api/UserProfileFavorite/GetUserFavoritesForUserId?userGuid=${userGuid}`,
            err
        );
    }
    return [] as IUserFavorite[];
};

export default getUserFavorites;
