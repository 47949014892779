import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";
import axios from 'axios';

export const executeSchedule = async (
    accessToken: string,
    eventId: number,
    scheduleId: number,
    userId: number
) => {
    
    const url = 'https://ctmsmartdatagenerateschedulefunction.azurewebsites.net/api/Function1?eventId=' + eventId + '&scheduleId=' + scheduleId + '&userId=' + userId + '&code=iQzAbmfRz06TcwhJ/jxjXnYrZa1rvx6yR8ZO2Sc4BZUEIzawmkh/Nw==';      
        
    try {
        // Make the GET request using axios and await the response
        const response = await axios.get(url);

        // Check if response exists
        if (response && response.data) {
            console.log("Data fetched:", response.data);

            // Return the data from the response
            return response.data;
        } else {
            // Return an empty string if no data was fetched
            return "";
        }
    } catch (err) {
        // Handle any errors and log them
        console.error("Error fetching data:", err);

        // Return an empty string in case of an error
        return "Done" +"eventId=" + eventId + "&scheduleId=" + scheduleId + "&userId=" + userId ;
    }
};


export default executeSchedule;
