import IUserSchedule from "../models/IUserSchedule";
import { fetchSecureData } from "../utils";

export const getUserSchedules = async (
  accessToken: string,
    userId: number,
    userGuid: string,
    jobId: number,
    scheduleId:number
) => {
  try {
    const data = await fetchSecureData(
      "GET",
        `/api/Schedule/GetSchedulesForUserId?userGuid=${userGuid}&jobId=${jobId}&scheduleId=${scheduleId}`,
      accessToken
    );
    const userSchedules = ((await data.json()) as any)
      .value as IUserSchedule[];
    if (userSchedules !== undefined) {
      return userSchedules;
    } else {
      return [] as IUserSchedule[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/Schedule/GetSchedulesForUserId?userGuid=${userGuid}&jobId=${jobId}&scheduleId=${scheduleId}`,
      err
    );
  }
  return [] as IUserSchedule[];
};

export default getUserSchedules;
