import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlQaS2TProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlQaS2T extends React.Component<
  ICallbackSamlQaS2TProps
> {
    public constructor(props: ICallbackSamlQaS2TProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

    auth.loginSAMLQaS2T().then(() => {
      console.log("Login via SAML QA S2T");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlQaS2T);
