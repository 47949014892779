import IGroup from "../models/IGroup";
import { fetchSecureData } from "../utils";

export const getUserGroups = async (accessToken: string, userId: number, userGuid: string) => {
  try {
    const data = await fetchSecureData(
      "GET",
        `/api/Group/GetGroupsForUserGuid?userGuid=${userGuid}`,
      accessToken
    );
    const userGroups = ((await data.json()) as any).value as IGroup[];
    if (userGroups !== undefined) {
      return userGroups;
    } else {
      return [] as IGroup[];
    }
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
      `Fetch error for /api/Group/GetGroupsForUserGuid?userGuid=${userGuid}`,
      err
    );
  }
  return [] as IGroup[];
};

export default getUserGroups;
