import IPbiPaginatedReport from "../models/IPbiPaginatedReport";
import { fetchSecureData } from "../utils";

export const getUserPaginatedReports = async (
    accessToken: string,
    userId: number,
    userGuid: string
) => {
    try {
        const data = await fetchSecureData(
            "GET",
            `/api/PbiPaginatedReport/GetPbiPaginatedReportsForUserId?userGuid=${userGuid}`,
            accessToken
        );
        const userPaginatedReports = ((await data.json()) as any)
            .value as IPbiPaginatedReport[];
        if (userPaginatedReports !== undefined) {
            return userPaginatedReports;
        } else {
            return [] as IPbiPaginatedReport[];
        }
    } catch (err) {
        // tslint:disable-next-line:no-console
        console.log(
            `Fetch error for /api/PbiPaginatedReport/GetPbiPaginatedReportsForUserId?userGuid=${userGuid}`,
            err
        );
    }
    return [] as IPbiPaginatedReport[];
};

export default getUserPaginatedReports;
