import IRole from "../models/IRole";
import { fetchSecureData } from "../utils";

export const setActiveRole = async (
  accessToken: string,
  roleId: number,
   userGuid: string
) => {
  try {
    const data = await fetchSecureData(
      "PUT",
        `/api/RoleUser/SaveActive?roleId=${roleId}&userGuid=${userGuid}`,
      accessToken
    );
      const roleUserId = ((await data.json()) as any).value as number;
      return roleUserId;
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log(
        `Fetch error for /api/RoleUser/SaveActive?roleId=${roleId}&userGuid=${userGuid}`
    );
  }
  return 0;
};

export default setActiveRole;
