import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    FormFeedback,
    Input
} from "reactstrap";
import { handleLoadReports } from "../../actions/reports";
import DataLoading from "../organisms/DataLoading";
import paths from "../../constants";
import ISqlReport from "../../models/ISqlReport";
import { IStore } from "../../models/IStore";
import FavoritesIcon from "../molecules/FavoritesIcon";
import NotFavoritesIcon from "../molecules/NotFavoritesIcon";
import IUserFavorite from "../../models/IUserFavorite";
import { handleAddFavorite, handleDeleteFavorite } from "../../actions/userFavorites";
import IPbiPaginatedReport from "../../models/IPbiPaginatedReport";
import { handleLoadPaginatedReports } from "../../actions/paginatedReports";


export interface IListUserUnitedReportsPageProps {
    accessToken: string;
    onHandleLoadReports: (accessToken: string) => void;
    Reports: ISqlReport[];
    ReportsLoaded: boolean;
    userId: number;
    userGuid: string;
    activeRoleId: number;

    userFavoritesReports: IUserFavorite[];
    userFavoritesPaginatedReports: IUserFavorite[];

    onHandleAddFavorite: (accessToken: string, favorite: IUserFavorite, userGuid:string) => void;
    onHandleDeleteFavorite: (accessToken: string, favoriteId: number) => void;

    paginatedReports: IPbiPaginatedReport[];
    paginatedReportsLoaded: boolean;
    onHandleLoadPaginatedReports: (accessToken: string) => void;
}

export interface IListUserUnitedReportsPageState {
    filteredReports: ISqlReport[];
    filteredPaginatedReports: IPbiPaginatedReport[];
    matchedNameExact: boolean;
    name: string;
    noMatchingNames: boolean;
    userFavorites: IUserFavorite[];
}

class ListUserUnitedReportsPage extends React.Component<
    IListUserUnitedReportsPageProps,
    IListUserUnitedReportsPageState
> {
    public isLoaded = false;
    public state = {
        filteredReports: [] as ISqlReport[],
        filteredPaginatedReports: [] as IPbiPaginatedReport[],

        matchedNameExact: false,
        name: "",
        noMatchingNames: false,
        userFavorites: [] as IUserFavorite[],
        userFavoritesPaginatedReports: [] as IUserFavorite[]

    };

    constructor(props: IListUserUnitedReportsPageProps) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);

        if (props.userFavoritesReports.length > 0 && props.userFavoritesPaginatedReports.length > 0) {
            this.state.userFavorites = props.userFavoritesReports.concat(props.userFavoritesPaginatedReports);
        }
        else {
            if (props.userFavoritesReports.length > 0) {
                this.state.userFavorites = props.userFavoritesReports
            }
            if (props.userFavoritesPaginatedReports.length > 0) {
                this.state.userFavorites = props.userFavoritesPaginatedReports
            }
        }
    }

    public handleInputChange(e: any): void {
        e.preventDefault();
        const { Reports, paginatedReports } = this.props;

        const searchReportDisplayName = e.target.value.toLowerCase();
        if (!searchReportDisplayName) {
            this.setState({
                filteredReports: Reports
            });
        } else {
            const filteredReports: ISqlReport[] = Reports.filter(
                (Report: ISqlReport) =>
                    Report.displayName
                        .toLowerCase()
                        .includes(searchReportDisplayName)
            );
            if (filteredReports.length === 1) {
                this.setState({ matchedNameExact: true, noMatchingNames: false });
            } else if (filteredReports.length === 0) {
                this.setState({ noMatchingNames: true, matchedNameExact: false });
            } else {
                this.setState({ matchedNameExact: false, noMatchingNames: false });
            }
            this.setState({
                filteredReports
            });
        }


        const searchPaginatedReportDisplayName = e.target.value.toLowerCase();
        if (!searchPaginatedReportDisplayName) {
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        } else {
            const filteredPaginatedReports: IPbiPaginatedReport[] = paginatedReports.filter(
                (paginatedReport: IPbiPaginatedReport) =>
                    paginatedReport.displayName
                        .toLowerCase()
                        .includes(searchPaginatedReportDisplayName)
            );
            if (filteredPaginatedReports.length === 1) {
                this.setState({ matchedNameExact: true, noMatchingNames: false });
            } else if (filteredPaginatedReports.length === 0) {
                this.setState({ noMatchingNames: true, matchedNameExact: false });
            } else {
                this.setState({ matchedNameExact: false, noMatchingNames: false });
            }
            this.setState({
                filteredPaginatedReports
            });
        }
    }



    public toggleFavorite = (ReportId: number) => {
        const {
            accessToken,
            userId,
            userGuid,
            activeRoleId,
            onHandleAddFavorite,
            onHandleDeleteFavorite
        } = this.props;


        if (this.state.userFavorites.find(fav => fav.reportId === ReportId && fav.reportType === "REPORT")) {
            const newUserFavorites: IUserFavorite[] = this.state.userFavorites.filter(fav => fav.reportId === ReportId && fav.reportType === "REPORT");
            onHandleDeleteFavorite(accessToken, newUserFavorites[0].id);


            // this.setState({ userFavorites: newUserFavorites  });


        }
        else {


            const newFav = {
                userProfileId: userId,
                roleId: activeRoleId,
                reportId: ReportId,
                reportType: 'REPORT',
                isEnabled: true
            } as IUserFavorite

            onHandleAddFavorite(accessToken, newFav, userGuid);

            //this.setState({ userFavorites: newUserFavorites }, () => {
            //    console.log(this.state.userFavorites.length)
            //});


        };
    };

    public toggleFavoritePaginatedReport = (paginatedReportId: number) => {
        const {
            accessToken,
            userId,
            userGuid,
            activeRoleId,
            onHandleAddFavorite,
            onHandleDeleteFavorite
        } = this.props;


        if (this.state.userFavorites.find(fav => fav.reportId === paginatedReportId && fav.reportType === "PAGINATED_REPORT")) {

            const newUserFavoritesPaginatedReports: IUserFavorite[] = this.state.userFavorites.filter(fav => fav.reportId === paginatedReportId && fav.reportType === "PAGINATED_REPORT");
            onHandleDeleteFavorite(accessToken, newUserFavoritesPaginatedReports[0].id);
        }
        else {
            const newFav = {
                userProfileId: userId,
                roleId: activeRoleId,
                reportId: paginatedReportId,
                reportType: 'PAGINATED_REPORT',
                isEnabled: true
            } as IUserFavorite

            onHandleAddFavorite(accessToken, newFav, userGuid);
        };
    };


    public async componentDidMount() {
        const {
            accessToken,
            onHandleLoadReports,
            Reports,
            ReportsLoaded,
            onHandleLoadPaginatedReports,
            paginatedReports,
            paginatedReportsLoaded
        } = this.props;
        this.isLoaded = true;
        if (this.isLoaded) {
            if (!ReportsLoaded) {
                onHandleLoadReports(accessToken);
            }
            this.setState({ filteredReports: Reports });

            if (!paginatedReportsLoaded) {
                onHandleLoadPaginatedReports(accessToken);
            }
            this.setState({ filteredPaginatedReports: paginatedReports });
        }
    }

    public componentDidUpdate(prevProps: IListUserUnitedReportsPageProps) {
        const { Reports, userFavoritesReports, paginatedReports, userFavoritesPaginatedReports } = this.props;


        if (prevProps.Reports !== Reports) {
            this.setState({
                filteredReports: Reports
            });
        }


        if (prevProps.paginatedReports !== paginatedReports) {
            this.setState({
                filteredPaginatedReports: paginatedReports
            });
        }



        if (prevProps.userFavoritesReports !== userFavoritesReports || prevProps.userFavoritesPaginatedReports !== userFavoritesPaginatedReports) {
            this.setState({
                userFavorites: userFavoritesReports.concat(userFavoritesPaginatedReports)
                //userFavorites: userFavoritesReports 
            })
        }


    }

    public render() {
        const { Reports, ReportsLoaded, paginatedReports, paginatedReportsLoaded } = this.props;
        const {
            noMatchingNames,
            matchedNameExact,
            name,
            filteredReports,
            userFavorites,
            filteredPaginatedReports
        } = this.state; 

        return (
            <React.Fragment>
                <h1 style={{ backgroundColor: "white" }}>Reports:</h1>
                {!ReportsLoaded && <DataLoading dataTitle="Reports" />}
                {ReportsLoaded && paginatedReportsLoaded && (
                    <React.Fragment>
                        <Card Style="width: 48rem; border-radius: 20% center">
                            <CardTitle tag="span">
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Search Report"
                                    onChange={this.handleInputChange}
                                    valid={matchedNameExact}
                                    invalid={noMatchingNames}
                                />
                                <FormFeedback valid={true}>
                                    {matchedNameExact && (
                                        <span>
                                            Report found! Select to edit
                                            {name}
                                        </span>
                                    )}
                                </FormFeedback>
                                <FormFeedback invalid="true">
                                    {noMatchingNames && (
                                        <span>No Reports match name entered </span>
                                    )}
                                </FormFeedback>
                            </CardTitle>
                            <CardBody>
                                {Reports.length > 0 &&
                                    filteredReports.map((Report: ISqlReport) => (
                                        <div key={Report.id}>
                                            <span onClick={() => this.toggleFavorite(Report.id)} className="mr-2">
                                                {
                                                    userFavorites.find(fav => fav.reportId === Report.id && fav.reportType === "REPORT") ?
                                                        <FavoritesIcon />
                                                        :
                                                        <NotFavoritesIcon />
                                                }
                                            </span>
                                            <Link to={paths.sqlReports.concat(Report.id.toString())}>
                                                {Report.displayName}
                                            </Link>
                                        </div>



                                    ))}

                                {paginatedReports.length > 0 &&
                                    filteredPaginatedReports.map((paginatedReport: IPbiPaginatedReport) => (
                                        <div key={paginatedReport.id}>
                                            <span className="mr-2" onClick={() => this.toggleFavoritePaginatedReport(paginatedReport.id)}>
                                                {
                                                    userFavorites.find(fav => fav.reportId === paginatedReport.id && fav.reportType === "PAGINATED_REPORT") ?
                                                        <FavoritesIcon />
                                                        :
                                                        <NotFavoritesIcon />
                                                }
                                            </span>

                                            <Link to={paths.pbiPaginatedReports.concat(paginatedReport.id.toString())}>
                                                {paginatedReport.displayName}
                                            </Link>
                                        </div>
                                    ))}
                            </CardBody>



                        </Card>

                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore) => {

    return {
        accessToken: state.auth0.accessToken,
        Reports: state.userReports,
        ReportsLoaded: state.loadingReports,
        userFavoritesReports: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "REPORT") : state.userFavorites,
        userFavoritesPaginatedReports: state.userFavorites.length > 0 ? state.userFavorites.filter(fav => fav.reportType === "PAGINATED_REPORT") : state.userFavorites,

        userId: state.userProfile.id,
        userGuid: state.userProfile.guid,
        activeRoleId: state.activeRoleId,
        paginatedReports: state.userPaginatedReports,
        paginatedReportsLoaded: state.loadingPaginatedReports,

        // userFavorites: state.userFavorites.filter(fav => fav.reportType === "REPORT")
    };
};

const mapDispatchToProps = (dispatch: any) => ({

    onHandleLoadPaginatedReports: (accessToken: string) => {
        dispatch(handleLoadPaginatedReports(accessToken));
    },

    onHandleLoadReports: (accessToken: string) => {
        dispatch(handleLoadReports(accessToken));
    },

    onHandleAddFavorite: (accessToken: string, newFav: IUserFavorite, userGuid:string) => {
        dispatch(handleAddFavorite(accessToken, newFav, userGuid));
    },

    onHandleDeleteFavorite: (accessToken: string, id: number) => {
        dispatch(handleDeleteFavorite(accessToken, id));
    }

});






export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListUserUnitedReportsPage);
