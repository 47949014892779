import * as React from "react";
import { connect } from "react-redux";
import { Input, Table } from "reactstrap";
import { settings } from "../../constants";
import ICurrencyCode from "../../models/ICurrencyCode";
import IFYStartMonth from "../../models/IFYStartMonth";
import IRole from "../../models/IRole";
import { IStore } from "../../models/IStore";

export interface IRoleDetailsTableProps {
    role: IRole;
    currencyCodes: ICurrencyCode[];
    fyStartMonths: IFYStartMonth[];
}

const RoleDetailsTable: React.SFC<IRoleDetailsTableProps> = (
    props: IRoleDetailsTableProps
) => {
    const { currencyCodes, fyStartMonths, role } = props;
    const { displayName, name, currencyCodeId, fyStartMonthId, isEnabled, hierachyLevelNumber, hierachyLevelNames } = role;
    const { allowDisableRoles } = settings;
    return (
        <Table striped={true}>
            {/* <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead> */}
            <tbody>
                <tr>
                    <th scope="row">Display Name</th>
                    <td> {displayName}</td>
                </tr>
                <tr>
                    <th scope="row">Name</th>
                    <td> {name}</td>
                </tr>
                <tr>
                    <th scope="row">Currency</th>
                    <td>
                        {" "}
                        {
                            (currencyCodes.find(
                                cc => cc.id === currencyCodeId
                            ) as ICurrencyCode).code
                        }
                    </td>
                </tr>
                <tr>
                    <th scope="row">Date Format</th>
                    <td>
                        {" "}
                        {
                            (currencyCodes.find(
                                cc => cc.id === currencyCodeId
                            ) as ICurrencyCode).dateFormat
                        }
                    </td>
                </tr>
                <tr>
                    <th scope="row">Financial Year Start Month</th>
                    <td>
                        {
                            (fyStartMonths.find(
                                fys => fys.id === fyStartMonthId
                            ) as IFYStartMonth).name
                        }
                    </td>
                </tr>


                {hierachyLevelNumber > 0 && (
                    <tr>
                        <th scope="row">Number of Hierachy Levels</th>
                        <td> 
                            {hierachyLevelNumber}
                        </td>
                    </tr>
                )} 

                {hierachyLevelNumber > 0 && (
                    <tr>
                        <th scope="row">Hierachy Levels Names</th>
                        <td>
                            {hierachyLevelNames}
                        </td>
                    </tr>
                )} 


                {allowDisableRoles && (
                    <tr>
                        <th scope="row">Enabled</th>
                        <td>
                            <Input type="checkbox" checked={isEnabled} readOnly={true} />
                            {isEnabled}
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        currencyCodes: state.currencyCodes,
        fyStartMonths: state.fyStartMonths
    };
};

export default connect(mapStateToProps)(RoleDetailsTable);
