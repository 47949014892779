import * as Redux from "redux";
import getUserReports from "../api/getUserReports";
import ISqlReport from "../models/ISqlReport";
import { setActiveReport } from "./activeReport";
import { setUserReportsLoaded } from "./loadingUserReports";

export const ADD_USER_REPORT = "ADD_USER_REPORT";
export const REMOVE_USER_REPORT = "REMOVE_USER_REPORT";
export const LOAD_USER_REPORTS = "LOAD_USER_REPORTS";

export const addUserReport = (userReport: ISqlReport) => {
  return {
    userReport,
    // tslint:disable-next-line:object-literal-sort-keys
    type: ADD_USER_REPORT
  };
};

export const removeUserReport = (id: number) => {
  return {
    id,
    type: REMOVE_USER_REPORT
  };
};

export const loadUserReports = (userReports: ISqlReport[]) => {
  return {
    userReports,
    // tslint:disable-next-line:object-literal-sort-keys
    type: LOAD_USER_REPORTS
  };
};

export const handleLoadUserReports = (accessToken: string, userId: number, userGuid: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const reports = await getUserReports(accessToken, userId, userGuid);
    if (reports !== undefined) {
      if (reports.length > 0) {
        reports.sort((a, b) =>
          a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
        );
        dispatch(loadUserReports(reports));
        dispatch(setActiveReport(reports[0].id));
      }
      dispatch(setUserReportsLoaded(true));
    }
  };
};
