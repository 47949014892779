import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlProdNaProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlProdNa extends React.Component<ICallbackSamlProdNaProps> {
  public constructor(props: ICallbackSamlProdNaProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

      auth.loginSAMLProdNa().then(() => {
          console.log("Login via SAML Prod North America");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlProdNa);
