import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Auth from "../../auth/Auth";
import { IStore } from "../../models/IStore";

export interface ICallbackSamlProdChinaProps extends RouteComponentProps {
  auth: Auth;
}

export class CallbackSamlProdChina extends React.Component<
  ICallbackSamlProdChinaProps
> {
    public constructor(props: ICallbackSamlProdChinaProps) {
    super(props);
  }

  public componentDidMount() {
    const { auth } = this.props;

    auth.loginSAMLProdChina().then(() => {
      console.log("Login via SAML Prod China");
    });
  }

  public render() {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    auth: state.auth0
  };
};

export default connect(mapStateToProps)(CallbackSamlProdChina);
