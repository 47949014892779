import * as Redux from "redux";
import getUserGroups from "../api/getUserGroups";
import IUserGroup from "../models/IGroup";
import { setActiveUserGroupId } from "./activeUserGroup";
import { setUserGroupsLoaded } from "./loadingUserGroups";
export const ADD_USER_GROUP = "ADD_USER_GROUP";
export const REMOVE_USER_GROUP = "REMOVE_USER_GROUP";
export const LOAD_USER_GROUPS = "LOAD_USER_GROUPS";

export const addUserGroup = (userGroup: IUserGroup) => {
  return {
    type: ADD_USER_GROUP,
    userGroup
  };
};

export const removeUserGroup = (id: number) => {
  return {
    id,
    type: REMOVE_USER_GROUP
  };
};

export const loadUserGroups = (userGroups: IUserGroup[]) => {
  return {
    type: LOAD_USER_GROUPS,
    userGroups
  };
};

export const handleLoadUserGroups = (accessToken: string, userId: number, userGuid: string) => {
  return async (dispatch: Redux.Dispatch) => {
    const userGroups = await getUserGroups(accessToken, userId, userGuid);
    if (userGroups !== undefined) {
      if (userGroups.length > 0) {
        dispatch(loadUserGroups(userGroups));
        dispatch(setActiveUserGroupId(userGroups[0].id));
      }
      dispatch(setUserGroupsLoaded(true));
    }
  };
};
