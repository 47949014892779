import * as Redux from "redux";
import getUserRoles from "../api/getUserRoles";
import removeRoleUser from "../api/removeRoleUser";
import saveRoleUser from "../api/saveRoleUser";
import { setEditUserRolesLoaded } from "./loadingEditUserRoles";
export const ADD_EDIT_USER_ROLE_ID = "ADD_EDIT_USER_ROLE_ID";
export const REMOVE_EDIT_USER_ROLE_ID = "REMOVE_EDIT_USER_ROLE_ID";
export const LOAD_EDIT_USER_ROLE_IDS = "LOAD_EDIT_USER_ROLE_IDS";
export const CLEAR_EDIT_USER_ROLE_IDS = "CLEAR_EDIT_USER_ROLE_IDS";

export const addEditUserRoleId = (id: number) => {
  return {
    id,
    type: ADD_EDIT_USER_ROLE_ID
  };
};

export const removeEditUserRoleId = (id: number) => {
  return {
    id,
    type: REMOVE_EDIT_USER_ROLE_ID
  };
};

export const loadEditUserRoleIds = (ids: number[]) => {
  return {
    ids,
    type: LOAD_EDIT_USER_ROLE_IDS
  };
};

export const clearEditUserRoleIds = () => {
  return {
    type: CLEAR_EDIT_USER_ROLE_IDS
  };
};

export const handleLoadEditUserRoleIds = (
  accessToken: string,
    userId: number,
    guid: string
) => {
  return async (dispatch: Redux.Dispatch) => {
      const userRoles = await getUserRoles(accessToken, guid);
    if (userRoles === undefined) {
      dispatch(clearEditUserRoleIds());
      dispatch(setEditUserRolesLoaded(false));
    } else {
      if (userRoles.length > 0) {
        dispatch(loadEditUserRoleIds(userRoles.map(role => role.id)));
      }
      dispatch(setEditUserRolesLoaded(true));
    }
  };
};

export const handleSaveEditUserRoleId = (
  accessToken: string,
  userId: number,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await saveRoleUser(accessToken, roleId, userId);
    dispatch(addEditUserRoleId(roleId));
  };
};

export const handleDeleteEditUserRoleId = (
  accessToken: string,
  userId: number,
  roleId: number
) => {
  return async (dispatch: Redux.Dispatch) => {
    await removeRoleUser(accessToken, roleId, userId);
    dispatch(removeEditUserRoleId(roleId));
  };
};
