import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Text from "../atoms/Text";
import HelpdeskCard from "../molecules/HelpdeskCard";
import HelpIcon from "../molecules/HelpIcon";
import PrivacyPolicyCard from "../molecules/PrivacyPolicyCard";
import UserManualCard from "../molecules/UserManualCard";

export interface ISupportModalState {
  modal: boolean;
}
export interface ISupportModalProps {
  className?: string;
}
export default class SupportModal extends React.Component<
  ISupportModalProps,
  ISupportModalState
> {
  public state: ISupportModalState = {
    modal: false
  };
  constructor(props: ISupportModalProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  public toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  public render() {
    const {  className } = this.props;
    return (
        <div>
        <div onClick={this.toggle}>
                <HelpIcon />       
                <Text style={{ color: "#FFF", paddingLeft: "8px"  }}>Support</Text>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={className}
        >
          <ModalHeader toggle={this.toggle}>Support</ModalHeader>
          <ModalBody>
            <HelpdeskCard />
         {/*   <UserManualCard />*/}
            <PrivacyPolicyCard />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-info" onClick={this.toggle}>
              OK
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
