import { handleLoadFinancialYears } from "actions/financialYears";
import IFinancialYear from "models/IFinancialYear";
import IPredicate from "models/IPredicate";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import {
    Button,
    Spinner,
    Form,

} from "reactstrap";

import {
    clearActiveDashboard,
    setActiveDashboard
} from "../../actions/activeDashboard";
import { handleRefreshToken } from "../../actions/tokenData";
import { settings } from "../../constants";
import ICurrencyCode from "../../models/ICurrencyCode";
import IFYStartMonth from "../../models/IFYStartMonth";
import IPbiDashboard from "../../models/IPbiDashboard";
import { IPbiData } from "../../models/IPbiData";
import { IPbiTokenData } from "../../models/IPbiTokenData";
import IRole from "../../models/IRole";
import IUserProfile from "../../models/IUserProfile";

import { IStore } from "../../models/IStore";
import { ITokenData } from "../../models/ITokenData";
import Jumbotron from "../atoms/Jumbotron";
import ActiveRoleBadge from "../molecules/ActiveRoleBadge";
import ResetSizeToWindowButton from "../molecules/ResetSizeToWindowButton";

import IPbiBookmark from "../../models/IPbiBookmark";
import BookmarkDropDownMenu from "../organisms/BookmarkDropDownMenu";
import { handleLoadBookmarks } from "actions/bookmarks";
import userProfile from "../../reducers/userProfile";
import ShareIcon from "../molecules/ShareIcon";
import AssignDashboardSharedUsers from "../templates/AssignDashboardSharedUsers";

import { IPbiDashboardShared } from "../../models/IPbiDashboardShared";


export interface IDashboardViewProps {
    apiAccessToken: string;
    currencyCodes: ICurrencyCode[];
    dashboard: IPbiDashboard;
    fyStartMonths: IFYStartMonth[];
    token: ITokenData;
    activeUserRole: IRole;
    activeUser: IUserProfile;
    defaultClientName: string;
    defaultPredicateDisplayName: string;
    onSetActiveDashboard: (id: number) => void;
    onClearActiveDashboard: () => void;
    onHandleRefreshToken: (accessToken: string, refreshToken: string) => void;
    roleCount: number;
    fys: IFinancialYear[];
    fysLoaded: boolean;
    onHandleLoadFinancialYears: (accessToken: string) => void;

    bookmarks: IPbiBookmark[];
    bookmarksLoaded: boolean;
    onHandleLoadBookmarks: (accessToken: string, roleId: number, userProfileId: number, pbiDashboardId: number) => void;
    loadingEmailedPbiDashboard: boolean,
    emailedPbiDashboard: IPbiDashboardShared
}

export interface IDashboardViewState {
    currency: string;
    embedUrl: string;
    fetched: boolean;
    fetching: boolean;
    fitToWindowVisible: boolean;
    fystart: number;
    height: string;
    intervalId: number;
    valuesLoaded: boolean;
    pbiToken: IPbiTokenData;
    width: string;
    activeBookmark: IPbiBookmark;
    initialBookmarkState: string;
    showDashboardShareModal: boolean;
}

export class DashboardView extends React.Component<
    IDashboardViewProps,
    IDashboardViewState
> {
    public state = {
        currency: "",
        embedUrl: "",
        fetched: false,
        fetching: true,
        fitToWindowVisible: false,
        fystart: 0,
        height: "",
        intervalId: 0,
        valuesLoaded: false,
        pbiToken: {
            expiration: "",
            token: "",
            tokenId: ""
        },
        width: "",
        activeBookmark: {} as IPbiBookmark,
        initialBookmarkState: "",
        showDashboardShareModal: false
    };

    public report: any;

    constructor(props: IDashboardViewProps) {
        super(props);
        this.getCurrentFinancialYear = this.getCurrentFinancialYear.bind(this);
        this.configureKpiPageFilters = this.configureKpiPageFilters.bind(this);
        this.configureSimulationPageFilters = this.configureSimulationPageFilters.bind(
            this
        );
        this.handleDataSelected = this.handleDataSelected.bind(this);
        this.handleReportLoad = this.handleReportLoad.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.setFullscreen = this.setFullscreen.bind(this);
        this.setRefresh = this.setRefresh.bind(this);
        this.printReport = this.printReport.bind(this);
        this.getPbiReportAccessToken = this.getPbiReportAccessToken.bind(this);
        this.getReportInGroup = this.getReportInGroup.bind(this);
        this.tokenRefresh = this.tokenRefresh.bind(this);
        this.getBookmarkCallback = this.getBookmarkCallback.bind(this);
        this.setBookmarkCallback = this.setBookmarkCallback.bind(this);
        this.toggleSelectUserModalCallback = this.toggleSelectUserModalCallback.bind(this);

        this.report = null; // to store the loaded report's object to perform operations like print, fullscreen etc...
    }





    public toggleSelectUserModalCallback() {
        this.setState({ showDashboardShareModal: !this.state.showDashboardShareModal });
    }


    public getCurrentFinancialYear = (fyStartMonthId: number) => {
        const { fys } = this.props;
        const fyStart = new Date(new Date().getFullYear(), fyStartMonthId - 1, 1);
        const fyEnd = new Date(
            new Date().getFullYear() + 1,
            fyStartMonthId - 1,
            0,
            23,
            59,
            59,
            999
        );
        const today = new Date().getDate();
        const lastFinancialMonth = new Date(Date.now() - today * 864e5); //864e5 == 86500000 = 24(h)*60(m)*60(s)*1000(ms)

        // console.table("Financial Year Start ID: ", fyStartMonthId);
        // console.table("Financial Year Start Date", fyStart);
        // console.table("Financial Year End Date", fyEnd);
        // console.table("Today's Date: ", today);
        // console.table("Last Financial Month's last day: ", lastFinancialMonth);
        // console.table("Financial Year starts after the last financial month date", fyStart<lastFinancialMonth);
        // console.table("Financial Year ends after the last financial month date? ", fyEnd>lastFinancialMonth);

        if (fyStart < lastFinancialMonth) {
            //New financial Year has started already for this calendar year
            return (fys.find(
                fy => fy.endYear === fyEnd.getFullYear()
            ) as IFinancialYear).displayName;
        } else {
            // New financial year has not yet started for this calendar year
            return (fys.find(
                fy => fy.endYear === fyEnd.getFullYear() - 1
            ) as IFinancialYear).displayName;
        }
    };

    public configureKpiPageFilters = async (page: any) => {
        const { defaultClientName, defaultPredicateDisplayName } = this.props;
        const filters = await page.getFilters();
        const filterClient = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "KpiDimValue",
                // tslint:disable-next-line:object-literal-sort-keys
                column: "DimValueName"
            },
            // tslint:disable-next-line:object-literal-sort-keys
            logicalOperator: "And",
            conditions: [
                {
                    operator: "Is",
                    value: defaultClientName.substring(0, defaultClientName.length - 6) // To eliminate appended currency code e.g. (AUD)
                }
            ]
        };
        const filterPredicate = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "KpiDim",
                // tslint:disable-next-line:object-literal-sort-keys
                column: "DimName"
            },
            // tslint:disable-next-line:object-literal-sort-keys
            logicalOperator: "And",
            conditions: [
                {
                    operator: "Is",
                    value: defaultPredicateDisplayName
                }
            ]
        };
        filters.push(filterClient);
        filters.push(filterPredicate);
        page.setFilters(filters);
    };

    public configureSimulationPageFilters = async (page: any) => {
        const { defaultClientName, defaultPredicateDisplayName } = this.props;
        const filters = await page.getFilters();
        // const filterClient = {
        //   $schema: "http://powerbi.com/product/schema#basic",
        //   target: {
        //     table: "KpiDimValue",
        //     column: "DimValueName"
        //   },
        //   logicalOperator: "And",
        //   conditions: [
        //     {
        //       operator: "Is",
        //       value: defaultClientName.substring(0, defaultClientName.length - 6) // To eliminate appended currency code
        //     }
        //   ]
        // };



        const filterClient = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "KpiDimValue",
                // tslint:disable-next-line:object-literal-sort-keys
                column: "DimValueName"
            },
            operator: "In",
            values: [defaultClientName.substring(0, defaultClientName.length - 6)]
        };
        // const filterPredicate = {
        //   $schema: "http://powerbi.com/product/schema#basic",
        //   target: {
        //     table: "KpiDim",
        //     column: "DimName"
        //   },
        //   logicalOperator: "And",
        //   conditions: [
        //     {
        //       operator: "Is",
        //       value: defaultPredicateDisplayName
        //     }
        //   ]
        // };
        const filterPredicate = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "KpiDim",
                // tslint:disable-next-line:object-literal-sort-keys
                column: "DimName"
            },
            operator: "In",
            values: [defaultPredicateDisplayName]
        };
        filters.push(filterClient);
        filters.push(filterPredicate);
        page.setFilters(filters);
    };

    public handleDataSelected = (data: any) => {
        // will be called when some chart or data element in your report clicked
    };

    public handleReportLoad = async (report: any) => {
        // will be called when report loads
        //this.report = report; // get the object from callback and store it. (optional)
        // console.log("handleReportLoad loaded");
        const { fyStartMonths, bookmarks, loadingEmailedPbiDashboard, emailedPbiDashboard, activeUserRole } = this.props;


        const pages = await this.report.getPages();

        // find the KPI Page and set the filters for it
        const kpiPage = pages.find(
            (page: any) => page.displayName === settings.kpiDashboardPageName
        );
        await this.configureKpiPageFilters(kpiPage);
        const simulationPage = pages.find(
            (page: any) => page.displayName === settings.simulationDashboardPageName
        );
        await this.configureSimulationPageFilters(simulationPage);
        //return focus to the Summary Page


        const filterCurrency = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "Currency",
                // tslint:disable-next-line:object-literal-sort-keys
                column: "Currency"
            },
            operator: "In",
            values: [this.state.currency]
        };

        const filterFinYearName = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "LkpClientFinYear",
                // tslint:disable-next-line:object-literal-sort-keys
                column: "FinYearName"
            },
            operator: "In",
            values: [this.getCurrentFinancialYear(this.state.fystart)]
        };
        const filterFinYearFirstMonth = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "LkpClientFinYear",
                column: "FinYearFirstMonth"
            },
            operator: "In",
            values: [
                (fyStartMonths.find(
                    month => month.id === this.state.fystart
                ) as IFYStartMonth).name
            ]
        };

      
       

        

        //get initial report state 
        // used when bookmarks are canceled
        

        const filters = await this.report.getFilters();
        await filters.push(filterCurrency);
        await filters.push(filterFinYearName);
        await filters.push(filterFinYearFirstMonth);
    
        if (activeUserRole.hierachyLevelNumber > 0) {
            const hierachyLevelNamesArray = JSON.parse(activeUserRole.hierachyLevelNames);

            //ToDo if field is empty then operator: "All", , else "In"

            for (let i = 1; i <= activeUserRole.hierachyLevelNumber; i++) {

                var filterLevelColumnName = "Level" + i;
                var  filterLevel1 = {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: "DimClient",
                        column: filterLevelColumnName
                    },
                    operator: hierachyLevelNamesArray.length > 0 ? "In" : "All",
                    values: hierachyLevelNamesArray.length > 0 ? [hierachyLevelNamesArray[i - 1]] : []
                };

              
                await filters.push(filterLevel1);


                var filterNodeDescColumnName = "Node" + i + "Desc";
                const filterNode1Desc = {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: "DimClient",
                        column: filterNodeDescColumnName
                    },
                    operator: "All",
                    values: []
                };
                
                await filters.push(filterNode1Desc);
            }

        }
     

        // console.table(filters);
        await this.report.setFilters(filters);
        //set first page
        pages[0].setActive();
        this.state.initialBookmarkState = (await this.getBookmarkCallback());


        //if report has bookmarks
        if (bookmarks.length > 0 && !loadingEmailedPbiDashboard) {

            let bkmrkArr: IPbiBookmark[] = bookmarks.filter(
                b => b.isDefault === true) as IPbiBookmark[];

            //set default bookmark
            if (bkmrkArr.length > 0) {
                this.setBookmarkCallback(bkmrkArr[0].id);
            }
            
        }
      
            //if open shared bookmark
            if (loadingEmailedPbiDashboard) {
                await this.report.bookmarksManager.applyState(emailedPbiDashboard.capturedBookmark);
            } 
     


        this.report.on("error", (error: any) => {
            // tslint:disable-next-line:no-console
            console.log("Error occurred");
            console.table(error);
        });






        // all events for reports listed below. hook in as needed
        ///////////
        // report.on('loaded', async (e: any) => {
        //   console.log('loaded called'); //<-- this wont ever fire since handleReportLoad fires when 'loaded' fires
        // });
        // report.on("saved", async (e: any) => {
        //   console.log("saved called");
        // });
        // report.on("rendered", async (e: any) => {
        //   console.log("rendered called");
        // });
        // report.on("saveAsTriggered", async (e: any) => {
        //   console.log("saveAsTriggered called");
        // });
        // report.on("error", async (e: any) => {
        //   console.log("error called");
        // });
        // report.on("dataSelected", async (e: any) => {
        //   console.log("dataSelected called");
        // });
        // report.on("buttonClicked", async (e: any) => {
        //   console.log("buttonClicked called");
        // });
        // report.on("pageChanged", async (e: any) => {
        //   console.log("pageChanged called");
        // });
        // report.on("commandTriggered", async (e: any) => {
        //   console.log("commandTriggered called");
        // });
        // report.on("swipeStart", async (e: any) => {
        //   console.log("swipeStart called");
        // });
        // report.on("swipeEnd", async (e: any) => {
        //   console.log("swipeEnd called");
        // });
        // report.on("bookmarkApplied", async (e: any) => {
        //   console.log("bookmarkApplied called");
        // });
        // report.on("dataHyperlinkClicked", async (e: any) => {
        //   console.log("dataHyperlinkClicked called");
        // });
        ///////////
    };

    public handlePageChange = (data: any) => {
        // will be called when pages in your report changes
    };

    public setFullscreen = () => {
        if (this.report) {
            this.setState({
                fitToWindowVisible: true,
                width: "99vw",
                height: "100vh"
            });
            this.report.fullscreen();
        }
    };

    public setRefresh = () => {
        if (this.report) {
            this.report.refresh().then((result: any) => {
                console.info("Report values refreshed");
            });
        }
    };

    public printReport = () => {
        if (this.report) {
            this.report.print();
        }
    };

    public async getPbiReportAccessToken(accessToken: string) {
        const { dashboard, activeUserRole, apiAccessToken } = this.props;
        const { groupId, reportId } = dashboard;
        const dashboardInfo = (await this.getReportInGroup(
            accessToken
        )) as IPbiData;
        this.setState({ embedUrl: dashboardInfo.embedUrl });
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${apiAccessToken}`);
        headers.append("AccessToken", accessToken);
        const init: RequestInit = {
            cache: "default",
            headers,
            method: "GET",
            mode: "cors"
        };
        // if the user has no roles, it will return all data, so we set an 'unknown' role if no roles found so that no data will be returned
        const url = `/api/PowerBiService/GetPowerBiReportToken?roles=${activeUserRole !== undefined ? activeUserRole.name : "Unknown"
            }&datasetId=${dashboardInfo.datasetId
            }&reportId=${reportId}&groupId=${groupId}`;
        const request = new Request(url, init);
        return fetch(request)
            .then(result => result.json())
            .then(data => {
                return data as IPbiTokenData;
            })
            .catch(err => {
                // tslint:disable-next-line:no-console
                console.log(`Fetch error for ${url}`, err);
                return {} as IPbiTokenData;
            });
    }

    public getReportInGroup(accessToken: string) {
        const { dashboard, apiAccessToken } = this.props;
        const { groupId, reportId } = dashboard;
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${apiAccessToken}`);
        headers.append("AccessToken", accessToken);
        const init: RequestInit = {
            cache: "default",
            headers,
            method: "GET",
            mode: "cors"
        };
        const url = `/api/PowerBiService/GetReportInGroup?groupId=${groupId}&reportId=${reportId}`;
        const request = new Request(url, init);
        return fetch(request)
            .then(result => result.json())
            .then(data => {
                return data as IPbiData;
            })
            .catch(err =>
                // tslint:disable-next-line:no-console
                console.log(`Fetch error for ${url}`, err)
            );
    }

    public tokenRefresh = async () => {
        const { apiAccessToken, token, onHandleRefreshToken } = this.props;
        onHandleRefreshToken(apiAccessToken, token.refresh_token);
    };


    public async componentDidUpdate(prevProps: IDashboardViewProps) {
        const { onSetActiveDashboard, dashboard } = this.props;
        if (prevProps.dashboard.id !== dashboard.id) {
            // tslint:disable-next-line:no-console
            console.log("props dashboard change detected, refreshing");
            onSetActiveDashboard(dashboard.id);
            await this.tokenRefresh();
        }
    }


    public async componentDidMount() {
        const {
            activeUserRole,
            activeUser,
            currencyCodes,
            fyStartMonths,
            dashboard,
            onSetActiveDashboard,
            fysLoaded,
            bookmarksLoaded,
            onHandleLoadFinancialYears,
            onHandleLoadBookmarks,
            apiAccessToken
        } = this.props;


        if (!fysLoaded) {
            onHandleLoadFinancialYears(apiAccessToken);
        }

        onSetActiveDashboard(dashboard.id);
        this.setState(() => ({ fetching: true, fetched: false }));
        await this.tokenRefresh();
        this.setState(() => ({ fetching: false, fetched: true }));
        const intervalId = window.setInterval(this.tokenRefresh, 55 * 60 * 1000); // 55 minutes between refreshes of PBI token
        this.setState(() => ({ intervalId }));

        if (activeUserRole === undefined) {
            this.setState({ currency: "AUD", fystart: 1 });
            throw new Error(
                "At least one role has not been defined, so default CurrencyCode to AUD with a FYStartMonth of January"
            );
        } else {
            const currency = (currencyCodes.find(
                cc => cc.id === activeUserRole.currencyCodeId
            ) as ICurrencyCode).code;
            const fystart = (fyStartMonths.find(
                fysm => fysm.id === activeUserRole.fyStartMonthId
            ) as IFYStartMonth).id;
            this.setState({
                currency,
                fystart
            });
        }
        this.setState({ width: "calc(99vw)", height: "calc(85vh)" });

        // if (!bookmarksLoaded) {
        onHandleLoadBookmarks(apiAccessToken, activeUserRole.id, activeUser.id, dashboard.id);
        //   } 
    }

    public getBookmarkCallback = async () => {
        var capturedBookmarkState = '';

        if (this.report) {
            var capturedBookmark = await this.report.bookmarksManager.capture({
                allPages: true,
                personalizeVisuals: true
            });
            capturedBookmarkState = capturedBookmark.state;
        }
        return capturedBookmarkState;
    };

    public clearBookmarkCallback = async () => {
        if (this.report) {
            await this.report.bookmarksManager.applyState(this.state.initialBookmarkState);
            //  await this.report.bookmarksManager.applyState('');
        }
    };

    public setBookmarkCallback = async (bookmarkId: number) => {
        const { bookmarks } = this.props;

        const bkmrk = (bookmarks.find(
            cc => cc.id === bookmarkId
        ) as IPbiBookmark);

        if (this.report) {

            await this.report.bookmarksManager.applyState(bkmrk.capturedBookmark);
        }
    };

    public shouldComponentUpdate(
        nextProps: IDashboardViewProps,
        nextState: IDashboardViewState
    ) {
        if (this.props.token.access_token !== nextProps.token.access_token) {
            if (nextProps.token.access_token !== "") {
                this.getPbiReportAccessToken(nextProps.token.access_token).then(
                    (pbiToken: IPbiTokenData) => {
                        console.log(pbiToken);
                        this.setState(() => ({ pbiToken }));

                        if (this.report !== null) {
                            this.report.setAccessToken(pbiToken.token);
                        }
                    }
                );
            }
            if (this.props.token.access_token === "") {
                return true;
            } else {
                return false;
            }
        }

        if (this.state.pbiToken.token !== nextState.pbiToken.token) {
            if (this.state.pbiToken.token === "") {
                return true;
            } else {
                return false;
            }
        }
        //else
        //{

        if (this.state.fitToWindowVisible !== nextState.fitToWindowVisible) {
            return false;
        }

        if (this.state.height !== nextState.height) {
            if (this.state.height === "") {
                return true;
            } else {
                return false;
            }
        }

        if (this.state.width !== nextState.width) {
            if (this.state.width === "") {
                return true;
            } else {
                return false;
            }
        }

        // }

        if (this.props.dashboard.id !== nextProps.dashboard.id) {
            return true;
        }

        if (this.props.dashboard.id !== nextProps.dashboard.id) {
            return true;
        }


        if (this.state.activeBookmark !== nextState.activeBookmark) {
            return true;
        }

        if (this.state.showDashboardShareModal !== nextState.showDashboardShareModal) {
            return true;
        }
        return false;
    }

    public componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    public render() {
        const { dashboard, roleCount } = this.props;
        const { reportId } = dashboard;
        const { fetching, fetched, pbiToken, embedUrl, height, width, showDashboardShareModal } = this.state;
        const reportStyle = {
            // style object for report component
            height,
            width
        };
        const extraSettings = {
            filterPanelEnabled: false, // true
            navContentPaneEnabled: true // false
            // any other custom settings
        };

        return (
            <React.Fragment>
                <Form>
                    <AssignDashboardSharedUsers showDashboardShareModal={this.state.showDashboardShareModal} toggleSelectUserModalCallback={this.toggleSelectUserModalCallback} getBookmarkCallback={this.getBookmarkCallback} pbiDashboardId={dashboard.id} />

                    <div style={{ margin: 6, float: "left", backgroundColor: "white" }}>
                        Dashboard: <strong>{dashboard.displayName}</strong>
                        {roleCount > 1 && (
                            <span>
                                {" "}
                                using Role: <strong><ActiveRoleBadge /></strong>
                            </span>
                        )}
                    </div>
                    <Button
                        onClick={this.setFullscreen}
                        color="info"
                        size="sm"
                        style={{ margin: 4, float: "right" }}
                    >
                        Go Full Screen
                    </Button>
                    <ResetSizeToWindowButton
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={() => {
                            this.setState({
                                fitToWindowVisible: false,
                                height: "calc(85vh)",
                                width: "calc(99vw)"
                            });
                        }}
                        isVisible={this.state.fitToWindowVisible}
                    />
                    <Button
                        onClick={this.printReport}
                        color="info"
                        size="sm"
                        style={{ margin: 4, float: "right" }}
                    >
                        Print
                    </Button>
                    <Button
                        onClick={this.setRefresh}
                        color="info"
                        size="sm"
                        style={{ margin: 4, float: "right" }}
                    >
                        &#8634;
                    </Button>
                    <Button
                        onClick={this.toggleSelectUserModalCallback}
                        color="info"
                        size="sm"
                        style={{ margin: 4, float: "right" }}
                    >
                        <ShareIcon />Share
                    </Button>
                    <div style={{ margin: 4, float: "right" }}>
                        <BookmarkDropDownMenu getBookmarkCallback={this.getBookmarkCallback} setBookmarkCallback={this.setBookmarkCallback} clearBookmarkCallback={this.clearBookmarkCallback} pbiDashboardId={dashboard.id} />
                    </div>
                    {fetching && !fetched && (
                        <Jumbotron
                            style={{ height: this.state.height, width: this.state.width }}
                        >
                            <h1>Loading...</h1>
                            <Spinner color="info" type="grow" />
                            <Spinner color="info" type="grow" />
                            <Spinner color="info" type="grow" />
                            <Spinner color="info" type="grow" />
                            <Spinner color="info" type="grow" />
                        </Jumbotron>
                    )}
                    {!fetching && fetched && (
                        <div id="embedContainer">
                            {!pbiToken && <div>Loading...</div>}
                            {pbiToken && pbiToken.token && (
                                <PowerBIEmbed
                                    embedConfig={{
                                        accessToken: pbiToken.token,
                                        embedUrl: embedUrl,
                                        id: reportId,
                                        type: 'report',
                                        tokenType: models.TokenType.Embed,
                                        permissions: models.Permissions.ReadWrite,
                                        settings: {
                                            extraSettings
                                        },
                                        background: models.BackgroundType.Transparent,
                                    }
                                    }
                                    cssClassName={
                                        "report-style-class"
                                    }
                                    eventHandlers={
                                        new Map([
                                            ['loaded', this.handleReportLoad],
                                            ['rendered', function (event: any) { console.log(event.detail); }],
                                            ['error', function (event: any) { console.log(event.detail); }]
                                        ])
                                    }
                                    getEmbeddedComponent={(embeddedReport: any) => {
                                        this.report = embeddedReport as Report;

                                    }}
                                />
                            )}
                        </div>
                    )}
                </Form>
            </React.Fragment>


        );
    }
}
const matchStateToProps = (state: IStore, ownProps: RouteComponentProps) => {
    const { match } = ownProps;
    let dashboard = {} as IPbiDashboard;
    if (match !== null) {

        var id = (match.params as any).id as string;

        if (id.length === 36) {
            id = state.emailedPbiDashboard.pbiDashboardId.toString();
        }


        dashboard = state.userDashboards.find(dash => {
            return dash.id === parseInt(id, 10);
        }) as IPbiDashboard;
    }
    const defaultPredicate = state.predicates.find(
        pred => pred.id === state.activeDashboardDefaultPredicateId
    ) as IPredicate;
    return {
        activeUserRole: state.userRoles.find(
            role => role.id === state.activeRoleId
        ) as IRole,
        activeUser: state.userProfile,
        roleCount: state.userRoles.length,
        // tslint:disable-next-line:object-literal-sort-keys
        apiAccessToken: state.auth0.accessToken,
        currencyCodes: state.currencyCodes,
        dashboard,
        defaultClientName: state.activeDashboardDefaultClient.displayName,
        defaultPredicateDisplayName: defaultPredicate
            ? defaultPredicate.displayName
            : "",
        fyStartMonths: state.fyStartMonths,
        token: state.tokenData,
        fys: state.financialYears,
        fysLoaded: state.loadingFinancialYears,
        bookmarks: state.bookmarks,
        bookmarksLoaded: state.loadingBookmarks,
        loadingEmailedPbiDashboard: state.loadingEmailedPbiDashboard,
        emailedPbiDashboard: state.emailedPbiDashboard

    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onClearActiveDashboard: () => {
        dispatch(clearActiveDashboard());
    },
    onSetActiveDashboard: (id: number) => {
        dispatch(setActiveDashboard(id));
    },
    // tslint:disable-next-line:object-literal-sort-keys
    onHandleRefreshToken: async (accessToken: string, refreshToken: string) => {
        await dispatch(handleRefreshToken(accessToken, refreshToken));
    },
    onHandleLoadFinancialYears: (accessToken: string) => {
        dispatch(handleLoadFinancialYears(accessToken));
    },
    onHandleLoadBookmarks: (accessToken: string, roleId: number, userProfileId: number, pbiDashboardId: number) => {
        dispatch(handleLoadBookmarks(accessToken, roleId, userProfileId, pbiDashboardId));
    }

});

const DashboardViewPageV2 = connect(
    matchStateToProps,
    mapDispatchToProps
)(DashboardView);

export default DashboardViewPageV2;
